<template>
  <div id="footer">
    <!-- 热点问题输入联想等的slot -->
    <!-- 当前提问范围状态等的展示 -->
    <pop-list
      ref="popList"
      :show.sync="verbDisplay"
      @on-selected="selectedItem"
    ></pop-list>

    <!-- 正在录音的动画 -->
    <div class="voice-load" v-show="isRecording" @touchend="gtouchend">
      <img src="@/assets/icons/speaking.gif" alt width="80%" />
    </div>

    <div style="position: relative; z-index: 20" ref="footer">
      <div class="footer-state-wrapper">
        <div class="footer-state-box">
          <!-- 范围的提示 -->
          <div class="state-area" v-if="navigation || examId">
            <button @click="examGoBack">{{ btnText }}</button>
            您已选择“
            <em :title="exam ? exam.name : ''">{{
              exam
                ? exam.name.length > 10
                  ? exam.name.substring(0, 10) + '...'
                  : exam.name
                : '无'
            }}</em>
            ”{{ text1 }}，{{ text }}
          </div>
        </div>
      </div>
      <!-- 输入框的位置 -->
      <div class="footer-wrapper">
        <div class="footer-container">
          <template v-if="inputMode === 'text'">
            <!--展示pc端样式-->
            <div
              class="footer-voice"
              @click="switchClick($event, 'voice')"
              v-if="voiceEnabled"
            ></div>
            <div class="footer-input">
              <div class="footer-send-btn" @click="sendQuestion"></div>
              <div class="footer-textarea-wrapper">
                <van-field
                  ref="inputer"
                  class="message-input"
                  v-model="question"
                  rows="1"
                  autosize
                  type="textarea"
                  maxlength="120"
                  @input="verbInput"
                  @focus="verbInput"
                  @keypress.enter.prevent="sendQuestion"
                />
                <!-- @blur="verbHide" -->
              </div>
            </div>
          </template>
          <template v-else>
            <!-- 语音输入时的状态-->
            <div
              class="footer-voice2"
              v-if="voiceEnabled"
              @click="switchClick($event, 'text')"
            ></div>
            <!--语音识别-->
            <div class="footer-input2" v-if="voiceEnabled">
              <!-- @touchmove.native="gtouchmove" -->
              <van-button
                @touchstart.native.stop.prevent="gtouchstart"
                @touchend.native="gtouchend"
                type="danger"
                :loading="loading"
                loading-text="初始化..."
                :class="loading == true ? 'buttonSh2' : 'buttonSh'"
                >{{ isRecording ? '松开结束' : '按住说话' }}</van-button
              >
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopList from '@/components/PopList'
import { Notify, Button, Field } from 'vant'
import { mapState, mapGetters } from 'vuex'
import ProcessMessage from '@/utils/message.filter'
// import ExpandingArea from '@/plugins/expandingArea'
import { buildData } from '@/components/Bubbles/interface'
import { exitScene } from '@/api/chat'
import getSdk from '@/plugins/voiceInput/index.js'
import _ from 'lodash'
const { initEnv, onStart, onStop } = getSdk()

export default {
  name: 'AppFooter',
  data () {
    return {
      lines: 1,
      verbDisplay: false,
      question: '',
      footerHeight: 0,
      input: null,
      text: '',
      btnText: '',
      text1: '',
      inputMode: 'text',
      timeOutEvent: 0, // 定时器
      loading: false, // 语音初始化标记
      voiceEnabled: false,
      isPressed: false,
      isRecording: false,
      delay: 250
    }
  },
  computed: {
    ...mapState([
      'messages',
      'appConfig',
      'navigation',
      'level',
      'voice',
      'examId',
      'examName',
      'uiMode'
    ]),
    ...mapGetters(['exam']),
    style () {
      const lineHeight = 0.38
      const maxLines = 3

      return {
        height: this.lines * lineHeight + 'rem',
        overflow: this.lines > maxLines ? 'auto' : 'hidden'
      }
    },

    validMessage () {
      const processMessage = new ProcessMessage(this.question)
      const { message: question } = processMessage.dropSpaces().dropEmoji()

      return question
    }
  },
  watch: {
    uiMode () {
      const $inputer = this.$refs.inputer
      // 清空输入框
      this.question = ''
      this.$nextTick(() => {
        this.inputMode = 'text'
        // 隐藏建议问
        this.verbDisplay = false
        $inputer && $inputer.adjustSize()
      })
    },
    question: {
      immediate: true,
      handler () {
        setTimeout(() => {
          this.footerHeight = this.$refs.footer.offsetHeight - 6
        }, 120)
      }
    },
    level: {
      immediate: true,
      handler (value) {
        switch (value) {
          case 1:
            this.text1 = ''
            this.text = '其他栏目请点击'
            this.btnText = '其他栏目'
            break
          case 2:
            this.text1 = ''
            this.text = '其他类别请点击'
            this.btnText = '其他类别'
            break
          case 3:
            this.text1 = ''
            this.text = '其他问题请点击'
            this.btnText = '其他问题'
            break
          case 4:
            this.text1 = ''
            this.text1 = '问题'
            this.text = '返回首页请点击'
            this.btnText = '返回首页'
            break
          default:
            this.text1 = ''
            this.text = ''
            this.btnText = ''
            break
        }
        // setTimeout(() => {
        //   this.footerHeight = this.$refs.footer.offsetHeight - 6
        // }, 120)
      }
    },
    validMessage (value) {
      // 检索数据
      // this.verbInput()
    },
    footerHeight: {
      handler (value) {
        this.$store.commit('updateLayout', {
          footerHeight: value
        })
      }
    }
  },
  methods: {
    // 提问问题
    sendQuestion (dataType) {
      if (this.validMessage.length < 1) {
        return false
      }
      // 检查是否存在输入有效的内容
      if (!dataType) dataType = 'footer-keyboard'
      this.$store.dispatch('askQuestion', {
        from: 2,
        type: 0,
        question: this.validMessage,
        dataType: dataType
      })
      // 隐藏联想列表
      this.verbDisplay = false
      this.$nextTick(() => {
        this.question = ''
        // this.$nextTick(() => {
        //   // this.input.setStyle(true)
        // })
      })
    },
    // 查找联想问题
    verbInput: _.throttle(
      function (e) {
        const max = 6
        let parent = e.target
        let i = 0
        let isPrevent
        while (i < max && parent) {
          if (
            Array.prototype.indexOf.call(
              parent.classList,
              'driver-highlighted-element'
            ) >= 0
          ) {
            isPrevent = true
            return
          }
          i++
          parent = parent.parentNode
        }
        if (isPrevent) {
          return
        }

        // 若选择了栏目或是输入框输入了内容
        // if (this.question) {
        //   this.$refs.popList.update(this.validMessage)
        // } else {
        //   if (this.navigation) {
        //     this.$refs.popList.update(this.validMessage)
        //   } else {
        //     this.verbDisplay = false
        //   }
        // }
        this.$refs.popList.update(this.validMessage)
      },
      300,
      {
        leading: false,
        trailing: true
      }
    ),
    verbHide () {
      var self = this
      setTimeout(function () {
        self.verbDisplay = false
      }, 100)
    },
    // 选择推荐问题处理
    selectedItem (data) {
      this.question = data.desc
      this.show = false
      // this.$nextTick(() => {
      //   this.input.setStyle(true) // 计算输入框高度
      // })
    },
    /**
     * 长按事件的处理逻辑
     */
    // 按下
    gtouchstart (e) {
      // 停止语音的播放
      this.evtBus.$emit('broadcastStop')
      if (this.isRecording) {
        this.isPressed = false
        this.stopRecord()
        return
      }
      this.isPressed = true
      this.timeOutEvent = +new Date()
      // 按住按钮延迟 450ms 开始录音
      setTimeout(() => {
        if (this.isPressed) {
          console.log('press start')
          this.startRecord()
        }
      }, this.delay)
      // 录音时长最长为1分钟，程序上停止掉录音时间
      setTimeout(() => {
        if (this.isPressed) {
          console.log('一分钟自动停止录音')
          this.stopRecord()
        }
      }, 1000 * 60)
    },
    // 松开
    gtouchend () {
      const currentTime = +new Date()
      if (!this.isPressed || currentTime - this.timeOutEvent < this.delay) {
        this.isPressed = false
        // 松开时，按住时间不够或者未触发按下不执行任何操作
        return false
      }
      this.isPressed = false
      this.stopRecord()
      console.log('press end')
    },
    // 移动
    gtouchmove (e) {
      console.log(e)
      // 移动直接设置成取消录音
      if (this.isRecording) {
        this.stopRecord(false)
      }
      this.isPressed = false
      console.log('press cancel')
    },
    // 调用当前环境的sdk去完成语音的操作
    startRecord () {
      this.loading = true
      onStart()
        .then(
          (e) => {
            console.log(e, '成功调取录音')
            this.loading = false
            this.isRecording = true
          },
          (err) => {
            console.log(err)
            Notify({
              type: 'danger',
              message: err.message || err.errMsg
            })
            this.isRecording = false
          }
        )
        .finally(() => {
          this.loading = false
        })
    },
    stopRecord (send = true) {
      this.isRecording = false
      onStop(send).then((res) => {
        // console.log(res)
        if (send) {
          this.question = res
          this.sendQuestion()
        }
      })
    },
    // 考试场景切换
    examGoBack () {
      /**
       * 重新推送考试列表
       * 会话列表从后向前查找到 type=exam 的数据，添加到列表中
       */
      console.log('examGoBack:' + this.$store.state.level)
      if (this.level === 4) {
        // 返回首页
        const examList = _.findLast(this.messages, (item) => item.type === 17)
        if (examList) {
          this.$store.commit('appendBubble', this.messages[0])
          this.$store.commit('appendBubble', examList)
          const examList1 = _.findLast(this.messages, (item) => item.type === 2)
          if (examList1) {
            this.$store.commit('appendBubble', examList1)
          }
          const it = {
            type: 18,
            content: '您也可以点击“近期热点咨询”或在输入框内直接输入您的问题'
          }
          this.$store.commit('appendBubble', buildData(it))
          this.$store.commit('appendBubble', this.voice[0])
        }
        this.$store.commit('storeNavigation', null)
        this.$store.commit('updateExam', null)
        this.$store.commit('updateExamId', null)
        this.$store.commit('updateExamName', null)
      } else if (this.level === 3) {
        const cc = []
        cc.push({
          type: 0,
          style: true,
          content: '请选择您要咨询的问题：'
        })
        cc.push({
          type: 23,
          content: this.exam.message.content
        })
        // 从消息列表中找到语音文件
        const chooseQuestionPls = _.findLast(
          this.voice,
          (item) =>
            item.dataType === 'exam-selected' ||
            item.dataType === 'subQuestion-selected'
        )
        console.log(chooseQuestionPls)
        cc.push(chooseQuestionPls)
        this.$store.commit('appendBubble', cc)
        // 切换level
        this.$store.commit('updateLevel', 2)
        // 切换考试
        this.$store.commit('updateExam') // 自动向上查找s
        // 退出当前的场景
        exitScene({
          chatId: this.appConfig.chatId,
          platform: this.appConfig.platform,
          orgCode: this.appConfig.orgCode,
          token: this.appConfig.token,
          navigationId: this.navigationId,
          question: this.examName,
          sid: this.$store.state.params.sid
        })
      } else if (this.level === 1) {
        const examList = _.findLast(this.messages, (item) => item.type === 17)
        if (examList) {
          this.$store.commit('appendBubble', examList)
          const it = {
            type: 18,
            content: '咨询其他问题您可以直接在输入框输入'
          }
          this.$store.commit('appendBubble', buildData(it))
        }
        this.$store.commit('storeNavigation', null)
        this.$store.commit('updateExam', null)
        this.$store.commit('updateExamId', null)
        this.$store.commit('updateExamName', null)
      } else {
        const examList = _.findLast(
          this.messages,
          (item) => item.type === 'exam'
        )
        if (examList) {
          this.$store.commit('appendBubble', examList)
          const it = {
            type: 18,
            content: '咨询其他问题您可以直接在输入框输入'
          }
          this.$store.commit('appendBubble', buildData(it))
        }
        this.$store.commit('updateExam', null)
        this.$store.commit('updateExamId', null)
      }
    },
    // 切换展示图标
    switchClick (evt, mode) {
      if (
        Array.prototype.indexOf.call(
          evt.target.classList,
          'driver-highlighted-element'
        ) >= 0
      ) {
        return
      }
      this.inputMode = mode
      this.question = ''
      if (mode === 'text') {
        this.stopRecord(false)
      }
      // else {
      //   // 提前请求授权问题
      //   preRequest().then(
      //     () => {},
      //     (err) => {
      //       Notify({
      //         type: 'danger',
      //         message: err.message || err.errMsg
      //       })
      //       this.switchClick('text')
      //     }
      //   )
      // }
    }
  },
  components: {
    [PopList.name]: PopList,
    [Button.name]: Button,
    [Field.name]: Field
  },
  mounted () {
    // this.input = new ExpandingArea(this.$refs.input)
    this.footerHeight = this.$refs.footer.offsetHeight
    // 初始化输入的sdk相关
    initEnv()
      .then(
        (e) => {
          console.log('initEnv-e:', e)
          this.voiceEnabled = true
        },
        (f) => {
          console.log('initEnv-f:', f)
          this.voiceEnabled = false
        }
      )
      .finally(() => {
        this.evtBus.$emit('envReady')
        this.$store.commit('updateVoiceState')
      })
  }
}
</script>

<style lang="scss" scoped>
$footer-icon-size: 65px;

#footer {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  z-index: 100;
  .footer-state-wrapper {
    // padding-top: 10px;
    overflow: hidden;
    background-color: #fff;
    .footer-state-box {
      background-color: #fff;
      padding-top: 10px;
      padding-left: 0.12rem;
      padding-right: 0.1rem;
      border-radius: 4px 4px 0 0;
      box-shadow: 0 0 10px rgba(79, 79, 79, 0.37);
      overflow: hidden;
    }

    .state-area {
      font-size: 0.14rem;
      line-height: 1.5em;
      color: #a8a5a5;
      margin-top: 5px;
      margin-bottom: 10px;
      @include clear();
      em {
        font-style: normal;
        @include font_color('theme-color-2');
      }
      button {
        float: right;
        @include button;
      }
    }
  }
  .voice-load {
    position: fixed;
    top: 50%;
    left: 50%;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    width: 225px;
    height: 260px;
    text-align: center;
    color: #fff;
    font-size: px2rem(14);
    z-index: 102;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
      display: block;
    }
  }
  .footer-wrapper {
    background-color: #fff;
    display: flex;
    align-items: center;
    // height: 0.55rem;
    padding: 2px 10px;
    .footer-container {
      width: 100%;
      display: flex;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      align-items: flex-end;
    }
    .footer-voice {
      width: $footer-icon-size;
      min-width: $footer-icon-size;
      height: $footer-icon-size;
      border-radius: $footer-icon-size;
      margin-right: 8px;
      margin-bottom: 0px;
      @include icon('theme-icon3');
      background-size: 50% 70% !important ;
      background-position: center center !important;
      border: 1px solid #d2d2d2;
      cursor: pointer;
    }
    .footer-voice2 {
      width: $footer-icon-size;
      min-width: $footer-icon-size;
      height: $footer-icon-size;
      border-radius: $footer-icon-size;
      margin-right: 8px;
      @include icon('theme-icon4');
      background-size: 50% !important ;
      background-position: center center !important;
      border: 1px solid #d2d2d2;
      cursor: pointer;
    }
    .footer-input {
      position: relative;
      z-index: 1;
      width: 100%;
      border: 1px solid #d2d2d2;
      border-radius: $footer-icon-size / 2;
      padding: 8px $footer-icon-size 8px 10px;
      overflow: hidden;
      @include clear();
      .footer-textarea-wrapper {
        padding: 5px 13px;
      }
      .message-input {
        display: block;
        padding: 0;
        width: 100%;
        font-size: 0.16rem;
        color: #303133;
        border: 0;
        outline: 0;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .footer-input2 {
      position: relative;
      z-index: 1;
      width: 100%;
      border-radius: 20px;
      overflow: hidden;
      @include clear();
      .buttonSh {
        width: 100%;
        height: $footer-icon-size + 4px;
        border-radius: $footer-icon-size + 4px;
        color: #fff;
        border: none;
        @include background_color('theme-color-1');
      }
      .buttonSh2 {
        width: 100%;
        height: $footer-icon-size + 4px;
        border-radius: $footer-icon-size + 4px;
        color: #fff;
        border: none;
        opacity: 0.5;
        @include background_color('theme-color-1');
      }
    }

    .footer-send-btn {
      position: absolute;
      z-index: 5;
      right: 0.04rem;
      bottom: 5px;
      width: $footer-icon-size - 4px;
      height: $footer-icon-size - 4px;
      // transform: translateY(50%);
      border-radius: $footer-icon-size -4px;
      @include icon('theme-icon1');
      background-size: contain;
      background-size: 100% 100% !important;
      cursor: pointer;
    }
  }
}
// .is-mobile .footer-input textarea {
//   margin-top: 0.11rem!important;
// }
</style>
