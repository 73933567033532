import Init from './initial'

class Recorder {
  constructor () {
    this.state = 'unknown' // unknown ready failed
  }

  checkState () {
    const self = this

    return new Promise((resolve, reject) => {
      if (self.state === 'ready') {
        resolve()
        return false
      }
      if (self.state === 'failed') {
        reject(new Error('failed'))
        return false
      }
      Init.checkEnv([
        'startRecord',
        'stopRecord',
        'onVoiceRecordEnd',
        'translateVoice'
      ]).then(
        (res) => {
          self.state = 'ready'
          resolve(res)
        },
        (err) => {
          self.state = 'failed'
          reject(err)
        }
      )
    })
  }

  preRequest () {
    const self = this

    return new Promise((resolve, reject) => {
      /* 当前的语音api是否可用 */
      if (self.state !== 'ready') {
        self.checkState().then(
          (res) => {
            Init.wx.startRecord({
              success: function () {
                console.log('正在录音……')
                self.stop()
                resolve()
              },
              cancel: function (res) {
                console.warn('用户取消录音')
                reject(res)
              },
              fail: function (res) {
                console.warn('录音授权失败')
                reject(res)
              }
            })
          },
          (err) => {
            reject(err)
          }
        )
      } else {
        resolve()
      }
    })
  }

  start () {
    const self = this

    return new Promise((resolve, reject) => {
      /* 当前的语音api是否可用 */
      if (self.state !== 'ready') {
        self.checkState().then(
          (res) => {
            Init.wx.startRecord({
              success: function () {
                console.log('正在录音……')
                resolve()
              },
              cancel: function (res) {
                console.warn('用户取消录音')
                reject(res)
              },
              fail: function (res) {
                console.warn('录音授权失败')
                reject(res)
              }
            })
          },
          (err) => {
            reject(err)
          }
        )
      } else {
        Init.wx.startRecord({
          success: function () {
            console.log('正在录音……')
            resolve()
          },
          cancel: function (res) {
            console.warn('用户取消录音')
            reject(res)
          },
          fail: function (res) {
            console.warn('录音授权失败')
            reject(res)
          }
        })
      }
    })
  }

  stop (tag) {
    return new Promise((resolve, reject) => {
      Init.wx.stopRecord({
        success: function (res) {
          // 不发送不识别
          if (!tag) {
            return resolve('')
          }
          Init.wx.translateVoice({
            localId: res.localId, // 需要识别的音频的本地Id，由录音相关接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res) {
              resolve(res.translateResult)
            },
            fail: function (res) {
              reject(res)
            }
          })
        },
        fail: function (res) {
          reject(res)
        }
      })
    })
  }
}

export default new Recorder()
