<template>
  <!-- 输入匹配问题 -->
  <div class="pop-panel" :class="show && 'visible'" :style="style">
    <div class="header">
      热点咨询
      <em class="close-btn" @click="closeSelf"></em>
    </div>
    <ul class="qa-list">
      <li
        v-for="item in list"
        :key="item.id"
        :class="{ active: selected && item.id === selected.id }"
        v-html="replaceDesc(item)"
        @click="selectItem(item)"
      ></li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { verbInput, getHotList } from '@/api/chat'

export default {
  name: 'PopList',
  props: {
    show: Boolean
  },
  data () {
    return {
      list: [],
      selected: null,
      style: null
    }
  },
  computed: {
    ...mapState(['ui', 'appConfig', 'chatId', 'navigation', 'exam']),
    uiMode () {
      return this.$store.state.uiMode
    }
    // style () {
    //   if (this.show) {
    //     // if (window.PLATFORM === 'pc') {
    //     //   return {
    //     //     paddingBottom: this.ui.footerHeight + (this.exam.length ? 20 : 10) + 'px'
    //     //   }
    //     // } else {
    //     //   return {
    //     //     paddingBottom: (this.ui.footerHeight + 10) + 'px'
    //     //   }
    //     // }
    //     return {
    //       paddingBottom: this.ui.footerHeight + (this.exam.length ? 40 : (this.uiMode === 1 ? 10 : 30)) + 'px'
    //     }
    //   }
    //   return ''
    // }
  },
  watch: {
    show (value) {
      if (!value) {
        this.list.splice(0)
      }
      this.$nextTick(this.computedStyle)
    }
  },
  methods: {
    computedStyle () {
      if (this.show) {
        this.style = {
          paddingBottom:
            this.ui.footerHeight +
            (this.exam.length ? 40 : this.uiMode === 1 ? 10 : 40) +
            'px'
        }
      } else {
        this.style = null
      }
    },
    async update (question) {
      if (this.selected && this.selected.desc === question) {
        // 如果问题和输入的问题一样就不展示列表了
        this.closeSelf()
        return false
      }
      this.selected = null
      const { platform, orgCode, token } = this.appConfig
      try {
        const params = {
          platform,
          orgCode,
          token,
          question,
          chatId: this.chatId
        }
        if (this.navigation) {
          params.navigationId = this.navigation.id
        }
        if (this.exam) {
          params.examId = this.exam.id
        }
        const request = question.length >= 1 ? verbInput : getHotList
        const { data } = await request(params)
        if (data && data.length) {
          this.list.splice(0, this.list.length, ...data)
          this.$emit('update:show', true)
        }
      } catch (e) {
        this.closeSelf()
      }
    },
    selectItem (item) {
      this.$emit('on-selected', {
        desc: item.question || item.desc,
        id: item.id || item.qaId,
        firstCategoryName: item.firstCategoryName
      })
      this.selected = item
      this.$parent.sendQuestion('pop-selected')
      this.closeSelf()
    },
    closeSelf () {
      this.$emit('update:show', false)
    },
    replaceDesc (item) {
      let desc = item.desc || item.question
      const seg = item.seg
      if (seg) {
        const segArray = seg.split(',')
        segArray.forEach((sseg) => {
          desc = desc.replace(
            sseg,
            '<span style="color:red;">' + sseg + '</span>'
          )
        })
      }
      return desc
    }
  }
}
</script>

<style lang="scss" scoped>
.pop-panel {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 20px;
  height: 0;
  overflow: hidden;
  background: $pop-bgc;
  border-radius: 8px 8px 0 0;
  padding-left: 0.14rem;
  padding-right: 0.15rem;
  margin-bottom: -0.11rem;
  transition: all ease 0.25s;
  &.visible {
    bottom: 20px;
    height: auto;
  }
  .header {
    position: relative;
    z-index: 1;
    line-height: 0.34rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 0.14rem;
    font-weight: bold;
    color: #fff;
    .close-btn {
      position: absolute;
      top: 0;
      right: -10px;
      width: 0.34rem;
      height: 0.34rem;
      cursor: pointer;
      background: url('../assets/icons/i-close-w.png') no-repeat center center;
      background-size: 0.14rem;
    }
  }
  .qa-list {
    padding: 0.06rem 0 0 0;
    margin: 0;
    list-style: none;
    font-size: 0.14rem;
    color: #fff;
    line-height: 0.23rem;
    max-height: 12em;
    overflow-x: hidden;
    li {
      margin-top: 0.03rem;
      margin-bottom: 0.03rem;
      padding-left: 0.25rem;
      cursor: pointer;
      &:hover,
      &:active,
      &:focus {
        @include background_color('theme-color-1');
        // background: $pop-item-hover;
        color: #fff;
      }
    }
  }
}
</style>
