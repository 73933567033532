import recorder from './recorder.js'
import Init from './initial'

export const initEnv = () => {
  return Init.initEnv({})
}
export const onStart = () => {
  return recorder.start()
}
export const onStop = (tag) => {
  return recorder.stop(tag)
}

export const preRequest = () => {
  return recorder.preRequest()
}
