/*
 * @Author: 刘春宇 aigoubuluo@126.com
 * @Date: 2022-09-23 16:26:36
 * @LastEditors: 刘春宇 aigoubuluo@126.com
 * @LastEditTime: 2022-09-26 11:51:36
 * @FilePath: /robot-service-app/src/plugins/voiceInput/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import * as weChat from './weixin/index.js'
import * as Recorder from './webview.js'

// 根据当前的环境返回语音的操作方法
export default function () {
  const platform = window.PLATFORM
  console.log('platform:', platform)
  switch (platform) {
    case 'weChat':
      console.log('weChat:', platform)
      return weChat
    case 'webview':
      console.log('webview:', platform)
      return Recorder
    case 'h5':
      console.log('h5:', platform)
      return Recorder
    default:
      console.log('process.env.NODE_ENV:', process.env.NODE_ENV)
      return process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'production'
        ? Recorder
        : {
          initEnv () {
            return new Promise((resolve, reject) => {
              console.log('funciton template initEnv')
              reject(new Error('funciton template initEnv'))
            })
          },
          onStart () {
            return new Promise((resolve, reject) => {
              console.log('funciton template onStart')
              reject(new Error('funciton template onStart'))
            })
          },
          onStop (tag) {
            return new Promise((resolve, reject) => {
              console.log('funciton template onStop')
              reject(new Error('funciton template onStop'))
            })
          },
          preRequest () {
            return new Promise((resolve, reject) => {
              console.log('funciton template onStop')
              reject(new Error('funciton template onStop'))
            })
          }
        }
  }
}
