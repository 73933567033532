import wx from 'weixin-jsapi'
// import mockWx from '@/plugins/voiceInput/weixin/mock/wxJsbridge.js'
import { wxConfig } from '@/api/chat'

class Env {
  constructor () {
    // this.wx = process.env.NODE_ENV === 'development' ? mockWx : wx
    this.wx = wx
    this.state = 'unknown' // unknown ready failed
  }

  initEnv (options) {
    // 为页面url设置正确的微信授权
    const self = this

    return new Promise((resolve, reject) => {
      const url =
        (options ? options.url : window.location.href) || window.location.href

      wxConfig(url)
        .then(
          (data) => {
            if (data.code === 0) {
              /* 设置参数配置的缓存 */
              self.wxConfig = {
                debug: window.config.wxDebug,
                appId: data.appid,
                timestamp: data.timestamp,
                nonceStr: data.nonceStr,
                signature: data.signature,
                url: url.split('#')[0]
              }
              this.wx.config({
                debug: window.config.wxDebug,
                appId: data.appid,
                timestamp: data.timestamp,
                nonceStr: data.nonceStr,
                signature: data.signature,
                jsApiList: [
                  'startRecord',
                  'stopRecord',
                  'onVoiceRecordEnd',
                  'translateVoice',
                  'updateAppMessageShareData',
                  'updateTimelineShareData',
                  'onMenuShareAppMessage',
                  'onMenuShareTimeline'
                ],
                success: function () {
                  console.log('config ready')
                  self.state = 'ready'
                  resolve()
                },
                fail: function () {
                  console.log('config failed')
                  self.state = 'failed'
                  reject(self.wxConfig)
                }
              })
              this.wx.ready(function () {
                console.log('ready ready')
                self.state = 'ready'
                resolve(self.wxConfig)
              })
              this.wx.error(function (err) {
                console.log('error failed')
                self.state = 'failed'
                reject(err)
              })
            }
          },
          (error) => {
            if (process.env.NODE_ENV === 'development') {
              resolve(self.wxConfig)
            }
            reject(error)
          }
        )
        .catch((e) => {
          reject(e)
        })
    })
  }

  checkEnv (api = 'updateAppMessageShareData') {
    let apis

    if (api.push && api.pop) {
      apis = api
    } else {
      apis = [api]
    }

    return new Promise((resolve, reject) => {
      this.wx.checkJsApi({
        jsApiList: apis,
        success: function (res) {
          resolve(res)
        },
        fail: function (err) {
          reject(err)
          console.log(err)
        }
      })
    })
  }
}

export default new Env()
