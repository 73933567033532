/*
 * @Author: 刘春宇 aigoubuluo@126.com
 * @Date: 2022-09-23 16:26:36
 * @LastEditors: 刘春宇 aigoubuluo@126.com
 * @LastEditTime: 2022-09-26 16:56:43
 * @FilePath: /robot-service-app/src/plugins/voiceInput/webview.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Recorder from 'js-audio-recorder'
import { audio2Txt } from '@/api/chat.js'
import { Toast, Notify } from 'vant'
const parameter = {
  sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
  sampleRate: 8000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
  numChannels: 1 // 声道，支持 1 或 2， 默认是1
}

const recorder = new Recorder(parameter)

export const initEnv = () => {
  // 生产环境要检查是不是 http 协议，是不是已经授权过语音使用
  return new Promise((resolve, reject) => {
    console.log('进入Promise')
    Recorder.getPermission().then(
      () => {
        console.log('权限获取到了')
        // navigator.mediaDevices.getUserMedia
        const href = window.location.href
        if (process.env.NODE_ENV === 'development') {
          console.log('process.env.NODE_ENV === development:', href)
          resolve()
        } else if (/^https:/.test(href)) {
          console.log('/^https:/.test(href):', href)
          resolve()
        } else {
          reject(new Error('当前环境不支持'))
          // eslint-disable-next-line no-undef
          cconsole.log('reject:', '当前环境不支持')
        }
      },
      () => {
        console.log(4)
        Notify({
          type: 'warning',
          message: '麦克风授权被拒绝/不支持，无法使用语音输入功能'
        })
        reject(new Error('权限未获取'))
      }
    )
  })
}

export const preRequest = () => {
  return initEnv()
}

export const onStart = () => {
  return recorder.start()
}

export const onStop = (tag) => {
  return new Promise((resolve, reject) => {
    recorder.stop()
    if (!tag) {
      return
    }
    const audio = recorder.getPCMBlob()
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: '正在识别...'
    })
    audio2Txt({ file: audio })
      .then((res) => {
        try {
          res = JSON.parse(res.data)
          resolve(res.result)
        } catch (e) {
          reject(e)
        }
      }, reject)
      .finally(() => {
        Toast.clear()
      })
  })
}
